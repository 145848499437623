import { Button, Card, Col, Row, Table, message, Input, DatePicker } from 'antd';
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

export const COMINGSOON = () => {

    return (
        <>
            <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <h2>COMING SOON</h2>
                    <p>Fitur ini akan segera dibuat. Mohon ditunggu</p>
                </Col>
            </Row>
        </>
    )
}


export default withRouter(COMINGSOON);

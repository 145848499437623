import { Col, Row, message } from 'antd';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import React, { useEffect, useState, useCallback } from "react";
import { fetchAllSurat, fetchAllByTanggalFilter, dashboard } from 'redux/features/surat';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Card } from 'antd';

export const AnnualStatisticData = [
  {
    title: 'Total Device',
    value: 'Loading...',
  },
  {
    title: 'Warning',
    value: 'Loading...',
  },
  {
    title: 'Total Sensor',
    value: 'Loading...',
  }
]

export const DefaultDashboard = () => {

  const [data, setData] = useState({

  })

  const getData = useCallback(async () => {
    try {
      axios.get("https://api.roythings.tech/lamps").then(doc => {
        console.log(doc.data)
        setData(doc.data)
      })
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }, [])

  useEffect(() => {
    setInterval(() => {
      getData()
    }, 1000)
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>Jika menemukan bug ataupun error pada aplikasi ini bisa langsung <a target='_blank' href='https://wa.me/6285899731884?text=Hi min, ada error atau bug di aplikasi surat surabah'>klik sini </a></p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Sensor Analog`}
                value={3 || "Loading..."}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Device`}
                value={17 || "Loading..."}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Warning`}
                value={"Coming Soon" || "Loading..."}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <img style={{ width: "100%" }} src={"/img/dashboard.jpeg"} alt=""></img>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>P1</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["P1"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>P2</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["P2"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>TK1</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["TK1"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>TK2</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["TK2"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>TK3</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["TK3"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>HE</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["HE"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>TW1</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["TW1"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>TW2</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["TW2"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>MV1</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["MV1"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>MV2</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["MV2"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>F1</h3>
            {/* <h1 style={{textAlign:"center"}}>{data["1"] ? "Hidup" : "Mati"}</h1> */}
            {data["F1"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>F2</h3>
            {data["F2"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>PH</h3>
            {data["PH"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>T1</h3>
            {data["T1"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>T2</h3>
            {data["T2"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>M1</h3>
            {data["M1"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card>
            <h3 style={{ textAlign: "center" }}>M2</h3>
            {data["M2"] ? <h1 style={{ textAlign: "center", color: "green" }}>Online</h1> : <h1 style={{ textAlign: "center", color: "red" }}>Offline</h1>}
          </Card>
        </Col>
      </Row>
    </>
  )
}


export default withRouter(DefaultDashboard);

import React, {  Suspense } from "react";
import { Switch,Redirect} from "react-router-dom";
import PrivateRoute from 'components/PrivateRoute'
import { strings } from "res";
import Loading from 'components/shared-components/Loading';

// Pages
import DASHBOARD from "./dashboard"
import SURAT_MASUK from "./surat-masuk"
import SURAT_KELUAR from "./surat-keluar"
import PENGGUNA from "./pengguna"
import LAPORAN from "./laporan"
import CARI_SURAT from "./cari-surat"
import COMING_SOON from "./coming-soon"

// Detail
import DETAIL_SURAT_KELUAR from "./detail-surat-keluar"
import DETAIL_SURAT_MASUK from "./detail-surat-masuk"
import DETAIL_PENGGUNA from "./detail-pengguna"

import SETTINGS from "./settings"

export const AppViews = ({match}) => {

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <PrivateRoute path={`${strings.navigation.path.dashboard}`} component={DASHBOARD} />
        <PrivateRoute path={`${strings.navigation.path.surat_masuk}`} component={COMING_SOON} />
        <PrivateRoute path={`${strings.navigation.path.surat_keluar}`} component={COMING_SOON} />
        <PrivateRoute path={`${strings.navigation.path.pengguna}`} component={COMING_SOON} />
        <PrivateRoute path={`${strings.navigation.path.laporan}`} component={COMING_SOON} />
        <PrivateRoute path={`${strings.navigation.path.cari_surat}`} component={COMING_SOON} />

        {/* DETAIL */}
        <PrivateRoute path={`${strings.navigation.path.detail_surat_masuk}`} component={COMING_SOON} />
        <PrivateRoute path={`${strings.navigation.path.detail_surat_keluar}`} component={COMING_SOON} />
        <PrivateRoute path={`${strings.navigation.path.detail_pengguna}`} component={COMING_SOON} />
        <PrivateRoute path={`${strings.navigation.path.settings}`} component={COMING_SOON} />

        <Redirect from={`${match.url}`} to={`${strings.navigation.path.dashboard}`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
